<template>
  <div class="fill-parent">
    <div class="top-fixed">
      <!-- <div class="manage-nav" v-if="isSelectMode">
        <a href="#" @click.prevent="selectAllClicked">全選</a>

        <p>已選取 {{ selectedClothIds.length }} 件</p>

        <a href="#" @click.prevent="cancelManageBtnClicked">取消</a>
      </div> -->

      <div class="container position-relative">
        <div class="row align-items-center">

          <div class="col-auto position-relative pr-0">
            <button class="search-btn" :class="{'advanced': hasAdvancedFilter}" @click="searchBtnClicked">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.5196 18.997C14.6726 18.997 18.0392 15.6398 18.0392 11.4985C18.0392 7.3572 14.6726 4 10.5196 4C6.36665 4 3 7.3572 3 11.4985C3 15.6398 6.36665 18.997 10.5196 18.997Z" stroke="#8D8D8D" stroke-width="2" stroke-miterlimit="10"/>
                <path d="M17.0996 16.1826L20.856 18.9903" stroke="#8D8D8D" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
              </svg>
            </button>
          </div>

          <div class="col position-relative">
            <ClothCategoryTabs @set="setCategoryFilter"></ClothCategoryTabs>
          </div>
        </div>
        
      </div>

      <div class="manage-nav inner" v-if="isSelectMode">
        <a href="#" @click.prevent="selectAllClicked">全選</a>

        <p>已選取 {{ selectedClothIds.length }} 件</p>

        <a href="#" @click.prevent="cancelManageBtnClicked">取消</a>
      </div>
    </div>

    <section class="bottom-scroll">
      <div class="scroller-div">
        <div class="container position-relative">
          <div>
            <ClothCardList
              ref="cardList"
              :cloths="clothsInUse"
              :filters="filters"
              :sortType="sortType"
              :sortDirectionDesc="sortDirectionDesc"
              :mode="clothListMode"
              :selectedIds="selectedClothIds"
              :showTitle="blockShowText"
              @clothClicked="clothClicked">
            </ClothCardList>
          </div>
          
        </div>
      </div>

      
    </section>

    <button class="view-text-button" v-if="isViewMode" @click="viewBtnClicked">
      <span v-show="blockShowText">目</span>
      <span v-show="!blockShowText">隱</span>
    </button>

    <button class="manage-button" v-if="isViewMode" @click="manageBtnClicked">

      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0559 5.98495V6.93257L6.00021 9.71716H8.25385L11.7668 7.70219L16 9.71716L17.7246 9.72804L12.6178 6.93327V6.34364C12.6178 5.75401 13.736 5.61434 14.2947 4.72357C14.6233 4.19992 14.7073 3.74086 14.7073 3.24318C14.7073 2.58475 14.0517 0.5 11.7668 0.5C9.65384 0.5 8.72718 2.16359 8.72718 3.11121C8.72718 4.11429 10.2428 4.43157 10.3526 3.18281C10.4223 2.3861 11.0817 2.03795 11.7547 2.03795C12.1545 2.03795 13.0539 2.33837 13.0539 3.27477C13.0539 3.27477 13.1265 4.07216 12.3127 4.44279C12.3127 4.44279 11.0559 4.90957 11.0559 5.98495Z" fill="#4D4D4D"/>
        <path d="M3 10.5V7.17703C3 6.76813 3.24895 6.40042 3.62861 6.24856L8 4.5C8 5.5 8.8 7.5 12 7.5C15.2 7.5 16 5.5 16 4.5L20.3714 6.24856C20.751 6.40042 21 6.76813 21 7.17703V10.5C21 11.0523 20.5523 11.5 20 11.5H19C18.4477 11.5 18 11.9477 18 12.5V19.5C18 20.0523 17.5523 20.5 17 20.5H7C6.44772 20.5 6 20.0523 6 19.5V12.5C6 11.9477 5.55228 11.5 5 11.5H4C3.44772 11.5 3 11.0523 3 10.5Z" fill="white" stroke="#4D4D4D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

      <span>整理</span>
    </button>

    <div class="manage-tools" v-if="isSelectMode">
      <button @click="recycleClicked">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M4.5 7.5V15.5C4.5 17.3856 4.5 18.3284 5.08579 18.9142C5.67157 19.5 6.61438 19.5 8.5 19.5H15.5C17.3856 19.5 18.3284 19.5 18.9142 18.9142C19.5 18.3284 19.5 17.3856 19.5 15.5V7.5M4.5 7.5H19.5M4.5 7.5H2.92857C2.76151 7.5 2.67798 7.5 2.61699 7.46168C2.58519 7.4417 2.5583 7.41481 2.53832 7.38301C2.5 7.32202 2.5 7.23849 2.5 7.07143V7.07143C2.5 6.06905 2.5 5.56786 2.72991 5.20195C2.8498 5.01115 3.01115 4.8498 3.20195 4.72991C3.56786 4.5 4.06905 4.5 5.07143 4.5H18.9286C19.931 4.5 20.4321 4.5 20.798 4.72991C20.9889 4.8498 21.1502 5.01115 21.2701 5.20195C21.5 5.56786 21.5 6.06905 21.5 7.07143V7.07143C21.5 7.23849 21.5 7.32202 21.4617 7.38301C21.4417 7.41481 21.4148 7.4417 21.383 7.46168C21.322 7.5 21.2385 7.5 21.0714 7.5H19.5" stroke="#4D4D4D" stroke-width="1.8"/>
          <path d="M9.5 14.5H14.5" stroke="#4D4D4D" stroke-width="1.8" stroke-linecap="round"/>
        </svg>
        <span>淘汰</span>
      </button>
      <button class="d-none">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M6.5 8.5L4.78246 9.64502C4.31358 9.95761 4.07914 10.1139 3.90942 10.3204C3.75917 10.5032 3.64643 10.7139 3.57767 10.9403C3.5 11.196 3.5 11.4778 3.5 12.0413V15.62C3.5 16.6281 3.5 17.1321 3.69619 17.5172C3.86876 17.8559 4.14413 18.1312 4.48282 18.3038C4.86786 18.5 5.37191 18.5 6.38 18.5H17.62C18.6281 18.5 19.1321 18.5 19.5172 18.3038C19.8559 18.1312 20.1312 17.8559 20.3038 17.5172C20.5 17.1321 20.5 16.6281 20.5 15.62V12.0413C20.5 11.4778 20.5 11.196 20.4223 10.9403C20.3536 10.7139 20.2408 10.5032 20.0906 10.3204C19.9209 10.1139 19.6864 9.95761 19.2175 9.64502L17.5 8.5" stroke="#4D4D4D" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M20.5 12.5H17.9415C17.2458 12.5 16.8979 12.5 16.636 12.6888C16.3742 12.8775 16.2642 13.2075 16.0442 13.8675L15.9558 14.1325C15.7358 14.7925 15.6258 15.1225 15.364 15.3112C15.1021 15.5 14.7542 15.5 14.0585 15.5H9.94152C9.2458 15.5 8.89794 15.5 8.63605 15.3112C8.37416 15.1225 8.26416 14.7925 8.04415 14.1325L7.95585 13.8675C7.73584 13.2075 7.62584 12.8775 7.36395 12.6888C7.10206 12.5 6.7542 12.5 6.05848 12.5H3.5" stroke="#4D4D4D" stroke-width="1.8"/>
          <path d="M9.5 5.5L12 3M12 3L14.5 5.5M12 3L12 10" stroke="#4D4D4D" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>移動</span>
      </button>
      <button @click="deleteClicked">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M7 4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V6H18.9897C18.9959 5.99994 19.0021 5.99994 19.0083 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H19.9311L19.0638 20.1425C18.989 21.1891 18.1182 22 17.0689 22H6.93112C5.88184 22 5.01096 21.1891 4.9362 20.1425L4.06888 8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H4.99174C4.99795 5.99994 5.00414 5.99994 5.01032 6H7V4ZM9 6H15V4H9V6ZM6.07398 8L6.93112 20H17.0689L17.926 8H6.07398ZM10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z" fill="#4D4D4D"/>
        </svg>
        <span>刪除</span>
      </button>
      <button @click="setPublicityClicked">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M12 4C13.6477 4 15 5.35228 15 7V10H9V7C9 5.35228 10.3523 4 12 4ZM17 10V7C17 4.24772 14.7523 2 12 2C9.24771 2 7 4.24772 7 7V10H6C4.89543 10 4 10.8954 4 12V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V12C20 10.8954 19.1046 10 18 10H17ZM6 12H18V20H6V12Z" fill="#4D4D4D"/>
        </svg>
        <span>隱私</span>
      </button>
    </div>

    <ClothFilterModal v-if="bottomFilterShow" 
      @back="closeFilterModal" 
      @confirm="confirmFilterModal"
      :filterTags="filterTags"
      :filterColors="filterColors"
      :filterPublic="filterPublic"
      :sortType="sortType" 
      :sortDirectionDesc="sortDirectionDesc"
      :showBlockText="blockShowText">
    </ClothFilterModal>


    <transition name="fade">
      <FullScreenModal v-if="showViewCloth" title="檢視單品" @back="closeViewClothModal">
        <ViewCloth :id="focusClothSerial" @edit="openEditModal" @close="closeViewClothModal" hide-recover-btn></ViewCloth>
      </FullScreenModal>
    </transition>

    <transition name="fade">
      <CreateCloth v-if="showEditCloth" @updated="clothUpdated" :id="focusClothSerial" @back="editClothCancel"></CreateCloth>
    </transition>

    <transition name="fade">
      <ClothDeleteModal v-if="showDeleteConfirmModal" @cancel="showDeleteConfirmModal = false" @confirm="deleteOkClicked"></ClothDeleteModal>
    </transition>

    <transition name="fade">
      <WcBottomModal
        v-if="showRecycleModal"
        @close="showRecycleModal = false"
        @confirm="recycleConfirmed"
        title="紀錄淘汰方式">
        <div class="px-3">
          <VerticalList :items="recycleOpts" v-model="recycleSelected">
          </VerticalList>
        </div>
      </WcBottomModal>
    </transition>

    <transition name="fade">
      <WcBottomModal
        v-if="showPublicityModal"
        @close="showPublicityModal = false"
        @confirm="publicityConfirmed"
        title="編輯單品狀態">
        <div class="px-3">
          <VerticalList :items="publicOpts" v-model="publicSelected">
          </VerticalList>
        </div>
      </WcBottomModal>
    </transition>
    
  </div>
</template>

<script>
// @ is an alias to /src
import ClothCardList from '@/components/clothList/ClothCardList.vue';
import ClothCategoryTabs from '@/components/clothList/ClothCategoryTabs.vue';
import ClothFilterModal from '@/components/clothList/ClothFilterModal.vue';
import FullScreenModal from '@/components/FullScreenModal.vue';
import ViewCloth from '@/views/closet/ViewCloth.vue';
import CreateCloth from '@/views/closet/CreateCloth.vue';

import ClothDeleteModal from '@/components/clothList/ClothDeleteModal.vue';
import WcBottomModal from '@/components/commons/WcBottomModal.vue';
import VerticalList from '@/components/commons/VerticalList.vue';

import { mapActions, mapState } from 'vuex';

export default {
  name: 'ClosetBrowser',
  data() {
    return {
      // filters: [],
      tabCategory: -1,

      filterPublic: -1,
      filterTags: [],
      filterColors: [],
      modalFilters: [],
      sortType: 'category',
      sortDirectionDesc: false,

      clothListMode: 'view',
      
      bottomFilterShow: false,
      blockShowText: true,
      
      savingBusyName: 'CLOTH_LIST_BUSY',

      // view and edit
      focusClothSerial: -1,
      showViewCloth: false,
      showEditCloth: false,
      
      // manage
      selectedClothIds: [],
      recycleSelected: -1,
      recycleOpts: ['捐贈', '二手販售/寄賣/轉售', '舊衣回收桶/回收', '垃圾處理', '其他'],
      publicSelected: -1,
      publicOpts: ['公開單品', '私人單品'],
      showDeleteConfirmModal: false,
      showRecycleModal: false,
      showPublicityModal: false,
    };
  },
  components: {
    ClothCardList,
    ClothCategoryTabs,
    ClothFilterModal,
    FullScreenModal,
    ViewCloth,
    CreateCloth,
    ClothDeleteModal,
    WcBottomModal,
    VerticalList,
  },
  computed: {
    ...mapState(['clothCategories']),
    cloths() {
      return this.$store.getters.clothWithWearCount;
    },
    clothsInUse() {
      const cs = this.cloths.filter((c) => {
        return !c.isDeleted && c.recycleType === -1;
      });
      return cs;
    },
    filters() {
      const filter = [];
      if (this.tabCategory !== -1) {
        filter.push({
          type: 'category1',
          value: this.tabCategory
        });
      }
      if (this.filterTags.length > 0) {
        filter.push({
          type: 'tags',
          value: this.filterTags
        });
      }
      if (this.filterColors.length > 0) {
        filter.push({
          type: 'color',
          value: this.filterColors,
        });
      }
      if (this.filterPublic !== -1) {
        filter.push({
          type: 'isPublic',
          value: this.filterPublic === 1,
        });
      }
      return filter;
    },
    isViewMode() {
      return this.clothListMode === 'view';
    },
    isSelectMode() {
      return this.clothListMode === 'select';
    },
    hasAdvancedFilter() {
      for (const f of this.filters) {
        if (f.type !== 'category1') {
          return true;
        }
      }
      return false;
    }
	},
  watch: {
    
  },
  async mounted() {
    
	},
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'showMsgModal', 'updateCloth']),
    setCategoryFilter(category) {
      this.tabCategory = category.value;
    },
    searchBtnClicked() {
      this.bottomFilterShow = true;
    },
    closeFilterModal() {
      this.bottomFilterShow = false;
    },
    confirmFilterModal(filter) {
      // console.log(filter);
      this.filterColors.splice(0, this.filterColors.length);
      for (const c of filter.filterColors) {
        this.filterColors.push(c);
      }
      this.filterTags.splice(0, this.filterTags.length);
      for (const c of filter.filterTags) {
        this.filterTags.push(c);
      }
      this.filterPublic = filter.filterPublic;
      this.sortType = filter.sortType;
      this.sortDirectionDesc = filter.sortDesc;
      this.blockShowText = filter.showBlockText;
      this.closeFilterModal();

    },
    manageBtnClicked() {
      if (this.isViewMode) {

        this.clothListMode = 'select';
      }
    },
    viewBtnClicked() {
      this.blockShowText = !this.blockShowText;
    },
    cancelManageBtnClicked() {
      if (this.isSelectMode) {
        this.selectedClothIds.splice(0, this.selectedClothIds.length);
        this.clothListMode = 'view';
      }
    },
    clothClicked(cloth) {
      if (this.isSelectMode) {
        const index = this.selectedClothIds.indexOf(cloth.serial);
        if (index === -1) {
          this.selectedClothIds.push(cloth.serial);
        } else {
          this.selectedClothIds.splice(index, 1);
        }
      } else {
        this.focusClothSerial = cloth.serial;
        this.showViewCloth = true;
      }
    },
    selectAllClicked() {
      for (const cloth of this.$refs.cardList.sortedCloths) {
        const index = this.selectedClothIds.indexOf(cloth.serial);
        if (index === -1) {
          this.selectedClothIds.push(cloth.serial);
        } else {
          this.selectedClothIds.splice(index, 1);
        }
      }
    },

    closeViewClothModal() {
      this.showViewCloth = false;
    },
    openEditModal() {
      this.closeViewClothModal();
      this.showEditCloth = true;
    },
    clothUpdated(cloth) {
      this.showEditCloth = false;
      this.updateCloth(cloth);
    },
    editClothCancel() {
      this.showEditCloth = false;
      this.showViewCloth = true;
    },
    recycleClicked() {
      if (this.selectedClothIds.length > 0) {
        this.recycleSelected = -1;
        this.showRecycleModal = true;
      } else {
        this.showMsgModal('請選擇一件以上單品');
      }
    },
    deleteClicked() {
      if (this.selectedClothIds.length > 0) {
        this.showDeleteConfirmModal = true;
      } else {
        this.showMsgModal('請選擇一件以上單品');
      }
    },
    setPublicityClicked() {
      if (this.selectedClothIds.length > 0) {
        this.publicSelected = -1;
        this.showPublicityModal = true;
      } else {
        this.showMsgModal('請選擇一件以上單品');
      }
    },
    async deleteOkClicked() {
      this.appendComponentBusy(this.savingBusyName);
      try {
        const updatedCloths = [];
        const processCloths = this.cloths.filter((cloth) => {
          return this.selectedClothIds.indexOf(cloth.serial) !== -1;
        });
        for (const cloth of processCloths) {
          const updatedCloth = await this.$store.dispatch('api/deleteClothPromise', cloth.serial);
          this.updateCloth(updatedCloth);
          updatedCloths.push(updatedCloth);
        }
        this.showDeleteConfirmModal = false;
        this.showMsgModal(`已刪除 ${this.selectedClothIds.length} 件單品。`);
        this.cancelManageBtnClicked();
      } catch(err) {
        this.emitError(err);
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
    async recycleConfirmed() {
      this.appendComponentBusy(this.savingBusyName);
      try {
        const updatedCloths = [];
        const processCloths = this.cloths.filter((cloth) => {
          return this.selectedClothIds.indexOf(cloth.serial) !== -1;
        });
        for (const cloth of processCloths) {
          const updatedCloth = await this.$store.dispatch('api/recycleClothPromise', {
            serial: cloth.serial,
            recycleType: this.recycleSelected,
          });
          this.updateCloth(updatedCloth);
          updatedCloths.push(updatedCloth);
        }
        this.showRecycleModal = false;
        this.showMsgModal(`已淘汰 ${this.selectedClothIds.length} 件單品。`);
        this.cancelManageBtnClicked();
      } catch(err) {
        this.emitError(err);
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
    async publicityConfirmed() {
      this.appendComponentBusy(this.savingBusyName);
      try {
        const updatedCloths = [];
        const processCloths = this.cloths.filter((cloth) => {
          return this.selectedClothIds.indexOf(cloth.serial) !== -1;
        });
        for (const cloth of processCloths) {
          const updatedCloth = await this.$store.dispatch('api/publicClothPromise', {
            serial: cloth.serial,
            isPublic: this.publicSelected === 0,
          });
          this.updateCloth(updatedCloth);
          updatedCloths.push(updatedCloth);
        }
        this.showPublicityModal = false;
        this.showMsgModal(`已編輯 ${this.selectedClothIds.length} 件單品。`);
        this.cancelManageBtnClicked();
      } catch(err) {
        this.emitError(err);
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .fill-parent {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .top-fixed {
    flex: 0 0 auto;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 2;
    background-color: #fff;
  }

  .bottom-scroll {
    flex: 1 1;
    position: relative;
    overflow: hidden;
    z-index: 1;
    .scroller-div {
      height: 100%;
      overflow-y: auto;
      position: relative;
    }
  }

  .top-sticky {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #fff;
  }

  .search-btn {
    border: none;
    background-color: transparent;
    padding: .25rem .5rem;
    &.advanced path {
      stroke: var(--icon-color-highlight);
    }
  }

  .manage-nav {
    display: flex;
    padding: .5rem 1rem;
    justify-content: space-between;
    align-items: center;

    background: var(--background-color-tertiary);

    &.inner {
      position: absolute;
      // top: .25rem;
      top: 110%;
      left: 10%;
      width: 80%;
      border-radius: .25rem;
      opacity: .9;
      box-shadow: 0 0 12px #656565;
      z-index: 2;
    }
    p {
      margin: 0;
    }
  }

  .manage-button {
    border: none;
    border-radius: 999px;
    background-color: #fff;
    color: var(--text-color-main);
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 1rem;
    right: .5rem;
    padding: 0.5rem 1.125rem;
    column-gap: .5rem;
    box-shadow: 0px 4px 12px 10px rgba(184, 184, 184, 0.08), 0px 4px 12px 0px rgba(100, 100, 100, 0.25);
    z-index: 3;
  }

  .view-text-button {
    border: none;
    border-radius: 999px;
    background-color: #fff;
    color: var(--text-color-main);
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 1rem;
    left: .5rem;
    padding: 0.5rem 1.125rem;
    column-gap: .5rem;
    box-shadow: 0px 4px 12px 10px rgba(184, 184, 184, 0.08), 0px 4px 12px 0px rgba(100, 100, 100, 0.25);
    z-index: 3;
    display: none !important;
  }

  .manage-tools {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%, 0);
    display: grid;
    grid-template-columns: auto auto;
    column-gap: .75rem;
    row-gap: 1rem;
    z-index: 3;
    
    button {
      display: flex;
      padding: 0.5rem 1.125rem;
      justify-content: center;
      align-items: center;
      gap: 0.125rem;

      border-radius: 6.25rem;
      background: var(--background-color-primary);
      box-shadow: 0px 4px 12px 0px rgb(112 112 112);
      border: none;
      span {
        white-space: nowrap;
        color: var(--text-color-main);
      }
    }
  }
</style>
